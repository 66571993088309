@tailwind base;
@tailwind components;

.hello {
    @apply bg-clip-text bg-gradient-to-br font-black from-orange-400 text-7xl text-transparent to-indigo-600 via-red-600;
}

.custom-btn {
    @apply text-2xl px-4 py-2 text-white bg-gradient-to-br from-gray-700 to-black border border-gray-600 rounded-xl shadow;
}

.highlight {
    @apply font-mono bg-yellow-200 px-1 rounded-sm text-pink-900;
}

@tailwind utilities;
